<template>
  <div class="home">
    <Card>
      <Form :model="searchForm" :label-width="120" :rules="ruleValidate" inline>
        <FormItem label="统计方式" prop="lx">
          <RadioGroup
            v-model="searchForm.lx"
            @on-change="
              (data) => this.handleChangeValue('统计方式', 'radio', 'lx', data)
            "
          >
            <Radio
              :label="item.id"
              v-for="item in radioList.lx"
              :key="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem label="周期">
          <Select
            v-model="searchForm.zq"
            style="width: 80px"
            @on-change="
              (value) => {
                this.handleChangeCycle('周期', 'string', 'zq', value);
              }
            "
          >
            <Option v-for="item in zqList" :value="item.name" :key="item.id">{{
              item.name
            }}</Option> </Select
          >&nbsp;
          <DateRange
            v-model="searchForm.bgq"
            :dateType="dateType"
            ref="DateRange"
            @change="
              (value) => this.handleChangeOption('日期', 'date', 'bgq', value)
            "
          ></DateRange>
        </FormItem>
        <FormItem>
          <Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
        </FormItem>
      </Form>
    </Card>
    <div class="tagbox">
      <template v-if="Object.keys(selectedList).length !== 0">
        已选条件：
        <template v-for="item in selectedList">
          <span v-if="item !== null" :key="item.formItem">
            <Tag
              v-if="item"
              type="border"
              closable
              @on-close="() => handleCloseAll(item)"
            >
              <span>{{ item.title }}:</span>
              <span v-for="(name, index) in item.data" :key="index"
                >{{ index > 0 ? "," : "" }}{{ name }}</span
              >
            </Tag>
            <span v-else>
              <Tag
                v-for="(name, index) in item.data"
                :key="name"
                type="border"
                closable
                @on-close="() => handleClose(index, item)"
              >
                <span v-if="item">{{ item.title }}:</span>
                <span>{{ name }}</span>
              </Tag>
            </span>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import tagBoxMixin from "@/views/share/tagBoxMixin";
import dateHandleMixin from "@/views/share/dateHandleMixin";
import { checkDate } from "@/utils/index";
export default {
  name: "SearchPanel",
  components: {},
  mixins: [tagBoxMixin, dateHandleMixin],
  data() {
    return {
      searchForm: {
        bgq: ["2015", "2020"], // 日期
        lx: "ztdcb", // 统计方式
        zq: "年度", // 周期
        size: 10,
        current: 1,
      },
      zqList: [
        {
          id: "2",
          name: "年度",
        },
        {
          id: "5",
          name: "半年度",
        },
      ],
      radioList: {
        lx: [
          {
            id: "ztdcb",
            name: "总土地储备",
          },
          {
            id: "xztdcb",
            name: "新增土地储备",
          },
        ],
      },
      dateType: "year",
      selectedList: {}, //已选条件
      ruleValidate: {
        lx: [{ required: true, message: "", trigger: "change" }],
      },
    };
  },
  mounted() {
    this.handleChangeOption("日期", "date", "bgq", ["2015", "2020"]);
    this.initRadioSelect();
    this.handleSearch();
  },
  methods: {
    initRadioSelect() {
      this.handleChangeValue("统计方式", "radio", "lx", "ztdcb");
    },
    handleChangeValue(name, type, formItem, value) {
      const checkedValue = value;
      const radioList = this.radioList[formItem];
      let list = [];
      for (let i = 0, len = radioList.length; i < len; i++) {
        if (radioList[i].id === checkedValue) {
          list.push(radioList[i].name);
          break;
        }
      }
      this.handleChangeOption(name, type, formItem, list);
    },
    handleChangeCycle(name, type, formItem, value) {
      this.searchForm[formItem] = value;
      if (value === "年度") {
        this.dateType = "year";
      } else {
        this.dateType = "year";
      }
      this.searchForm.bgq = ["", ""];
      this.$refs.DateRange.clearAll();
      delete this.selectedList.bgq;
    },
    handleCloseAll(item) {
      const { choosedType, data, formItem: type } = item;

      let selectedList = Object.assign({}, this.selectedList);
      selectedList[type] = null;
      this.selectedList = selectedList;
      this.searchForm[type] = [];
    },
    handleSearch() {
      const { bgq } = this.searchForm;
      if (!checkDate(bgq[0], bgq[1])) {
        this.$msg.error({ type: "remind", code: 4015 });

        return;
      }
      let searchForm = this.handleDateRule(bgq, "bgq", "日期", this.searchForm);
      if (searchForm === null) {
        return;
      }
      searchForm.bgq[0] += "-12-31";
      searchForm.bgq[1] += "-12-31";
      this.$emit("submit", searchForm);
    },
  },
};
</script>
