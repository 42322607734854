<template>
	<div class="page">
		<div class="page-ct">
			<div class="option-box">
				<div class="line city-line">
					<div class="title">选择地区</div>
					<div class="des">
						<Select v-model="formItem.province" style="width: 100px" size="small" placeholder="省份"
							@on-change="selectProvince">
							<Option v-for="item in landProvinceList" :value="item.areaId" :key="item.areaId">
								{{ item.name }}
							</Option>
						</Select>
						<Select v-model="formItem.city" style="width: 100px" size="small" placeholder="城市"
							@on-change="selectCity">
							<Option v-for="item in cityList" :value="item.areaId" :key="item.areaId">
								{{ item.name }}
							</Option>
						</Select>
						<Select v-model="formItem.region" style="width: 100px" size="small" placeholder="行政区">
							<Option v-for="item in regionList" :value="item.areaId" :key="item.areaId">
								{{ item.name }}
							</Option>
						</Select>
					</div>
				</div>
				<div class="line">
					<div class="title">出让方式</div>
					<div class="des">
						<Checkbox :value="checkSellAll" @click.prevent.native="
                handleCheckAll(checkSellAll, sellWay, 'sellWay')
              ">全选</Checkbox>
						<CheckboxGroup v-model="formItem.sellWay">
							<Checkbox v-for="(item, index) in sellWay" :key="index" :label="item.id">
								{{ item.name }}
							</Checkbox>
						</CheckboxGroup>
					</div>
				</div>
				<div class="line land-use">
					<div class="title">土地用途</div>
					<div class="des">
						<Checkbox :value="checkLandAll" @click.prevent.native="
                handleCheckAll(checkLandAll, landUse, 'landUse')
              ">全选</Checkbox>
						<CheckboxGroup v-model="formItem.landUse">
							<Checkbox v-for="(item, index) in landUse" :key="index" :label="item.id">
								{{ item.name }}
							</Checkbox>
						</CheckboxGroup>
					</div>
				</div>
				<div class="line saixuan-line">
					<div class="title">筛选条件</div>
					<div class="des">
						<Select v-model="formItem.dealState" style="width: 100px; margin: 0 4px 0 0" size="small"
							placeholder="成交状态">
							<Option v-for="item in dealState" :value="item.id" :key="item.id">{{ item.name }}</Option>
						</Select>

						<div class="select-card">
							<Button>
								起始价
								<Icon type="md-arrow-dropdown" />
							</Button>
							<div class="select-card__content">
								<Input type="number" v-model="formItem.startPriceStart" placeholder="起始价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'startPrice', 0)
                  " />
								<span class="split">-</span>
								<Input type="number" v-model="formItem.startPriceEnd" placeholder="起始价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'startPrice', 1)
                  " />
							</div>
						</div>
						<div class="select-card">
							<Button>
								成交价
								<Icon type="md-arrow-dropdown" />
							</Button>
							<div class="select-card__content">
								<Input type="number" v-model="formItem.transactionTotalPriceStart" placeholder="成交价"
									class="option-input sm" @on-change="
                    changeRangeVal(
                      $event.target.value,
                      'transactionTotalPrice',
                      0
                    )
                  " />
								<span class="split">-</span>
								<Input type="number" v-model="formItem.transactionTotalPriceEnd" placeholder="成交价"
									class="option-input sm" @on-change="
                    changeRangeVal(
                      $event.target.value,
                      'transactionTotalPrice',
                      1
                    )
                  " />
							</div>
						</div>
						<div class="select-card">
							<Button>
								推出单价
								<Icon type="md-arrow-dropdown" />
							</Button>
							<div class="select-card__content">
								<Input type="number" v-model="formItem.unitPriceStart" placeholder="推出单价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'unitPrice', 0)
                  " />
								<span class="split">-</span>
								<Input type="number" v-model="formItem.unitPriceEnd" placeholder="推出单价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'unitPrice', 1)
                  " />
							</div>
						</div>
						<div class="select-card">
							<Button>
								成交单价
								<Icon type="md-arrow-dropdown" />
							</Button>
							<div class="select-card__content">
								<Input type="number" v-model="formItem.transactionPriceStart" placeholder="成交单价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'transactionPrice', 0)
                  " />
								<span class="split">-</span>
								<Input type="number" v-model="formItem.transactionPriceEnd" placeholder="成交单价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'transactionPrice', 1)
                  " />
							</div>
						</div>
						<div class="select-card">
							<Button>
								推出楼面价
								<Icon type="md-arrow-dropdown" />
							</Button>
							<div class="select-card__content">
								<Input type="number" v-model="formItem.launchFloorPriceStart" placeholder="推出楼面价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'launchFloorPrice', 0)
                  " />
								<span class="split">-</span>
								<Input type="number" v-model="formItem.launchFloorPriceEnd" placeholder="推出楼面价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'launchFloorPrice', 1)
                  " />
							</div>
						</div>
						<div class="select-card">
							<Button>
								成交楼面价
								<Icon type="md-arrow-dropdown" />
							</Button>
							<div class="select-card__content">
								<Input type="number" v-model="formItem.floorPriceStart" placeholder="成交楼面价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'floorPrice', 0)
                  " />
								<span class="split">-</span>
								<Input type="number" v-model="formItem.floorPriceEnd" placeholder="成交楼面价"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'floorPrice', 1)
                  " />
							</div>
						</div>
						<div class="select-card">
							<Button>
								用地面积
								<Icon type="md-arrow-dropdown" />
							</Button>
							<div class="select-card__content">
								<Input type="number" v-model="formItem.landAreaStart" placeholder="用地面积"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'landArea', 0)
                  " />
								<span class="split">-</span>
								<Input type="number" v-model="formItem.landAreaEnd" placeholder="用地面积"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'landArea', 1)
                  " />
							</div>
						</div>
						<div class="select-card">
							<Button>
								建筑面积
								<Icon type="md-arrow-dropdown" />
							</Button>
							<div class="select-card__content">
								<Input type="number" v-model="formItem.buildAreaStart" placeholder="建筑面积"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'buildArea', 0)
                  " />
								<span class="split">-</span>
								<Input type="number" v-model="formItem.buildAreaEnd" placeholder="建筑面积"
									class="option-input sm" @on-change="
                    changeRangeVal($event.target.value, 'buildArea', 1)
                  " />
							</div>
						</div>
						<Select v-model="formItem.tmp" style="width: 100px; margin: 0 4px 0 0" size="small"
							placeholder="保障房类型">
							<Option v-for="item in dealState" :value="item.id" :key="item.id">{{ item.name }}</Option>
						</Select>
						<Select v-model="formItem.tmp" style="width: 100px; margin: 0 4px 0 0" size="small"
							placeholder="出让年限">
							<Option v-for="item in dealState" :value="item.id" :key="item.id">{{ item.name }}</Option>
						</Select>
						<Select v-model="formItem.tmp" style="width: 100px; margin: 0 4px 0 0" size="small"
							placeholder="是否含标书">
							<Option v-for="item in dealState" :value="item.id" :key="item.id">{{ item.name }}</Option>
						</Select>
					</div>
				</div>
				<div class="more-btn" @click="switchMore">更多条件</div>
				<div class="more" :class="{ open: open }">
					<div class="show-box" v-show="open">
						<div class="line">
							<div class="left">
								<div class="title">地块名称</div>
								<div class="des">
									<input type="text" placeholder="地块名称" class="option-input"
										v-model="formItem.landName" />
								</div>
							</div>
							<div class="right">
								<div class="title">受让单位</div>
								<div class="des">
									<input v-model="formItem.effectivePrescription" placeholder="受让单位" type="text"
										class="option-input" />
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">公告时间</div>
								<div class="des">
									<DatePicker v-model="formItem.announcementTimeStart" type="date" placeholder="开始时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'announcementTime', 0)"
										style="width: 120px"></DatePicker>
									<span class="split">-</span>
									<DatePicker v-model="formItem.announcementTimeEnd" type="date" placeholder="结束时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'announcementTime', 1)"
										style="width: 120px"></DatePicker>
								</div>
							</div>
							<div class="right">
								<div class="title">土地编号</div>
								<div class="des">
									<input v-model="formItem.landCode" placeholder="土地编号" type="text"
										class="option-input" />
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">溢价率</div>
								<div class="des">
									<input v-model="formItem.premiumRateStart" placeholder="溢价率" type="text"
										class="option-input sm" @input="
                      changeRangeVal($event.target.value, 'premiumRate', 0)
                    " />
									<span class="split">-</span>
									<input v-model="formItem.premiumRateEnd" placeholder="溢价率" type="text"
										class="option-input sm" @input="
                      changeRangeVal($event.target.value, 'premiumRate', 1)
                    " />
								</div>
							</div>
							<div class="right">
								<div class="title">起始时间</div>
								<div class="des">
									<DatePicker v-model="formItem.startingTimeStart" type="date" placeholder="开始时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'startingTime', 0)"
										style="width: 120px"></DatePicker>
									<span class="split">-</span>
									<DatePicker v-model="formItem.startingTimeEnd" type="date" placeholder="结束时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'startingTime', 1)"
										style="width: 120px"></DatePicker>
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">容积率</div>
								<div class="des">
									<input v-model="formItem.plotRatioStart" placeholder="容积率" type="text"
										class="option-input sm"
										@input="changeRangeVal($event.target.value, 'plotRatio', 0)" />
									<span class="split">-</span>
									<input v-model="formItem.plotRatioEnd" placeholder="容积率" type="text"
										class="option-input sm"
										@input="changeRangeVal($event.target.value, 'plotRatio', 1)" />
								</div>
							</div>
							<div class="right">
								<div class="title">成交时间</div>
								<div class="des">
									<DatePicker v-model="formItem.closingTimeStart" type="date" placeholder="开始时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'closingTime', 0)"
										style="width: 120px"></DatePicker>
									<span class="split">-</span>
									<DatePicker v-model="formItem.closingTimeEnd" type="date" placeholder="结束时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'closingTime', 1)"
										style="width: 120px"></DatePicker>
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">详细规划</div>
								<div class="des">
									<input v-model="formItem.detailedPlanning" placeholder="详细规划" type="text"
										class="option-input" />
								</div>
							</div>
							<div class="right">
								<div class="title">截止时间</div>
								<div class="des">
									<DatePicker v-model="formItem.deadlineStart" type="date" placeholder="开始时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'deadline', 0)"
										style="width: 120px"></DatePicker>
									<span class="split">-</span>
									<DatePicker v-model="formItem.deadlineEnd" type="date" placeholder="结束时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'deadline', 1)"
										style="width: 120px"></DatePicker>
								</div>
							</div>
						</div>
					</div>

					<div class="btn-line">
						<div class="btn check" @click="getList">查询</div>
						<div class="btn cancel">取消</div>
					</div>
					<div class="switch" @click="switchMore">
						<Icon type="ios-arrow-up" />
					</div>
				</div>
			</div>

			<div class="action-line">
				<!-- <div class="btn index">指标选择</div> -->
				<div class="btn save" @click="saveTemplate">保存模板</div>
				<div class="btn my" @click="showModalManage = true">我的模板</div>
			</div>
			<div class="choose-box">
				<div class="title">已选条件：</div>
				<div class="des">
					<Tag type="border" v-if="formItem.dealState" size="large" @on-close="delSingle('dealState')"
						closable>
						交易状态:
						<span class="tabContent">{{
              formatTradingStatus(formItem.dealState)
            }}</span>
					</Tag>
					<Tag type="border" v-if="formItem.province || formItem.city || formItem.region"
						@on-close="delSingle('city')" closable>
						地区:
						<span class="tabContent">
							{{ formatProvince(formItem.province) }}
							{{ formatCity(formItem.city) }}
							{{ formatRegion(formItem.region) }}
						</span>
					</Tag>
					<Tag type="border" v-if="formItem.sellWay.length > 0" @on-close="delSingleAll('sellWay')" closable>
						出让方式:
						<span class="tabContent" v-for="(item, index) in formItem.sellWay" :key="'sell' + index">{{ index > 0 ? "," : ""
              }}{{ sellWay.find((sw) => sw.id === item).name }}</span>
					</Tag>
					<Tag type="border" v-if="formItem.landUse.length > 0" @on-close="delSingleAll('landUse')" closable>
						土地用途:
						<span class="tabContent" v-for="(item, index) in formItem.landUse" :key="'land' + index">{{ index > 0 ? "," : ""
              }}{{ landUse.find((lu) => lu.id === item).name }}</span>
					</Tag>
					<Tag type="border" v-for="(item, key) in chooseFormItemOption" v-show="formItem[key]" :key="key"
						@on-close="delSingle(key, item)" closable>
						{{ item }}:
						<span class="tabContent">{{ formItem[key] }}</span>
					</Tag>
					<Tag type="border" v-for="(item, key) in chooseTimeOption"
						v-show="formItem[`${key}Start`] && formItem[`${key}End`]" :key="key"
						@on-close="delSingle(key, item)" closable>
						{{ item }}:
						<span class="tabContent">{{ formItem[key].join("-") }}</span>
					</Tag>
					<Tag type="border" v-for="(item, key) in chooseRangeOption"
						v-show="formItem[`${key}Start`] && formItem[`${key}End`]" :key="key"
						@on-close="delSingle(key, item)" closable>
						{{ item }}:
						<span class="tabContent">{{ formItem[key].join("-") }}</span>
					</Tag>
				</div>
			</div>
			<div class="table-box">
				<Table :columns="tableColumns" :data="tableData" border height="400" tooltip-theme="light" class="table"
					@on-row-click="tableClick" :loading="loading"></Table>
				<Page :total="total" show-elevator show-sizer @on-page-size-change="pageSizeChange"
					@on-change="pageChange" />
			</div>
		</div>
		<modalManage :saveType="2" @chooseTemplate="chooseTemplate"></modalManage>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import mixin from "@/mixins";
	import modalManage from "@/components/modal/modalManage";
	import tableBtn from "@/components/insight/TableBtn";
	import {
		getLandSingleSelectCity,
		getLandSingleSelectRegion,
	} from "@/api/public";
	import {
		post_enterprise_tdcb_tdlb
	} from "@/api/houseEnterprise";
	export default {
		name: "landList",
		components: {
			modalManage,
			// HorizenTable
		},
		mixins: [mixin],
		data() {
			return {
				open: false,
				showDetail: false,

				cityList: [],
				regionList: [],

				formItem: {
					province: "",
					city: "",
					region: "",
					dealState: "",
					size: 10,
					current: 1,
					// 公告时间
					announcementTime: [],
					announcementTimeStart: "",
					announcementTimeEnd: "",
					// 建筑面积
					buildArea: [],
					buildAreaStart: "",
					buildAreaEnd: "",
					// 成交时间
					closingTime: [],
					closingTimeStart: "",
					closingTimeEnd: "",
					// 截至时间
					deadline: [],
					deadlineStart: "",
					deadlineEnd: "",
					detailedPlanning: "",
					effectivePrescription: "",
					landCode: "",
					landName: "",
					landUse: [],
					// 容积率
					plotRatio: [],
					plotRatioStart: "",
					plotRatioEnd: "",
					// 溢价率
					premiumRate: [],
					premiumRateStart: "",
					premiumRateEnd: "",
					// 起始价
					startPrice: [],
					startPriceStart: "",
					startPriceEnd: "",
					// 成交价
					transactionTotalPrice: [],
					transactionTotalPriceStart: "",
					transactionTotalPriceEnd: "",
					// 推出单价
					unitPrice: [],
					unitPriceStart: "",
					unitPriceEnd: "",
					// 成交单价
					transactionPrice: [],
					transactionPriceStart: "",
					transactionPriceEnd: "",
					// 推出楼面价
					launchFloorPrice: [],
					launchFloorPriceStart: "",
					launchFloorPriceEnd: "",
					// 成交楼面价
					floorPrice: [],
					floorPriceStart: "",
					floorPriceEnd: "",
					// 用地面积
					landArea: [],
					landAreaStart: "",
					landAreaEnd: "",

					sellWay: [],
					startingPrice: [],
					// 起始时间
					startingTime: [],
					startingTimeStart: "",
					startingTimeEnd: "",
					transactionStatus: [],
					transferAge: "",
					typeOfSecurityRoom: "",
				},
				chooseFormItemOption: {
					landName: "地块名称",
					effectivePrescription: "受让单位",
					landCode: "土地编号",
					detailedPlanning: "详细规划",
				},
				chooseTimeOption: {
					announcementTime: "公告时间",
					closingTime: "成交时间",
					deadline: "截止时间",
					startingTime: "起始时间",
				},
				chooseRangeOption: {
					plotRatio: "容积率",
					premiumRate: "溢价率",
					startPrice: "起始价",
					transactionTotalPrice: "成交价",
					unitPrice: "推出单价",
					transactionPrice: "成交单价",
					launchFloorPrice: "推出楼面价",
					floorPrice: "成交楼面价",
					landArea: "用地面积",
					buildArea: "建筑面积",
				},
				pageSize: 10,
				currentIndex: 1,

				tableColumns: [{
						title: "",
						key: "pinggu",
						align: "center",
						ellipsis: true,
						tooltip: true,

						width: 60,
						render: (h, params) => {
							return h(tableBtn, {
								props: {},
								nativeOn: {
									click: () => {},
								},
							});
						},
					},
					{
						title: "地块名称",
						key: "zdbhCr",
						align: "center",
						ellipsis: true,
						tooltip: true,

						width: 140,
					},
					{
						title: "城市",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "cs",
					},
					{
						title: "土地用途",
						ellipsis: true,
						tooltip: true,

						key: "tdytCr",
						align: "center",
					},
					{
						title: "建筑用地面积",
						ellipsis: true,
						tooltip: true,

						key: "zydmj",
						renderHeader: (h, index) => {
							return h("div", [h("div", "建筑用地面积"), h("div", "（㎡）")]);
						},
						align: "center",
					},
					{
						title: "规划建筑面积",
						ellipsis: true,
						tooltip: true,

						key: "ghjzmj",
						renderHeader: (h, index) => {
							return h("div", [h("div", "规划建筑面积"), h("div", "（㎡）")]);
						},
						align: "center",
					},
					{
						title: "容积率",
						ellipsis: true,
						tooltip: true,

						key: "rjlms",
						align: "center",
					},
					{
						title: "出让方式",
						ellipsis: true,
						tooltip: true,

						key: "crfsCr",
						align: "center",
					},
					{
						title: "截止日期",
						ellipsis: true,
						tooltip: true,

						key: "gpjzsj",
						align: "center",
					},
					{
						title: "起始价（万元）",
						ellipsis: true,
						tooltip: true,

						key: "qsjms",
						align: "center",
						renderHeader: (h, index) => {
							return h("div", [h("div", "起始价"), h("div", "（万元）")]);
						},
					},
					{
						title: "成交楼面价（元/m²）",
						ellipsis: true,
						tooltip: true,

						key: "cjlmj",
						align: "center",
						renderHeader: (h, index) => {
							return h("div", [h("div", "成交楼面价"), h("div", "（元/m²）")]);
						},
					},
					{
						title: "溢价率",
						key: "yjl",
						ellipsis: true,
						tooltip: true,

						align: "center",
					},
					{
						title: "受让单位",
						key: "sldw",
						align: "center",
						ellipsis: true,
						tooltip: true,

						width: 140,
					},
				],
				tableData: [],
				total: 0,
				loading: false,
				land: {},
			};
		},
		computed: {
			...mapState(["sellWay", "dealState", "landProvinceList"]),
			landUse() {
				return this.$store.state.landUse.filter(
					(item) => !item.name.includes("+")
				);
			},
			checkSellAll() {
				return this.formItem.sellWay.length == this.sellWay.length;
			},
			checkLandAll() {
				return this.formItem.landUse.length == this.landUse.length;
			},
			showModalManage: {
				get() {
					return this.$store.state.modal.showModalManage;
				},
				set(newValue) {
					this.$store.state.modal.showModalManage = newValue;
				},
			},
			saveSearchData: {
				get() {
					return this.$store.state.saveSearchData;
				},
				set(newValue) {
					this.$store.state.saveSearchData = newValue;
				},
			},
			gpdm() {
				return this.$store.state.componeyCode;
			},
		},
		mounted() {
			this.getParams();
		},
		methods: {
			// 参数获取列表
			async getParams() {
				let item = this.formItem;
				let query = this.$route.query;
				if (Object.keys(query).length) {
					if (query.province) {
						item.province = +query.province;
						await this.selectProvince(item.province);
						query.city && (item.city = +query.city);
						await this.selectCity(item.city);
						query.region && (item.region = +query.region);
					}
					// item.city = query.city;
					item.landName = query.searchVal;
					query.sellWay && (item.sellWay = [+query.sellWay]);
					query.landUse && (item.landUse = [+query.landUse]);
					query.dealState && (item.dealState = +query.dealState);
					this.getList();
				}
			},
			changeTabs(index) {
				if (this.tabsIndex == index) return;
				this.tabsIndex = index;
			},
			switchMore() {
				this.open = !this.open;
			},
			async selectProvince(item) {
				return new Promise((resolve) => {
					getLandSingleSelectCity({
						id: item,
					}).then((data) => {
						this.cityList = data;
						this.formItem.city = "";
						this.regionList = [];
						this.formItem.region = "";
						resolve();
					});
				});
			},
			async selectCity(item) {
				return new Promise((resolve) => {
					getLandSingleSelectRegion({
						id: item,
					}).then((data) => {
						this.regionList = data;
						this.formItem.region = "";
						resolve();
					});
				});
			},

			// 全选checkbox用
			handleCheckAll(val, list, key) {
				let item = this.formItem;
				// 已经全选
				if (val) {
					item[key] = [];
				} else {
					item[key] = list.map((item) => item.id);
				}
				this.formItem = item;
			},
			// 已选提交的操作
			delSingle(type, val, index) {
				let item = this.formItem;
				if (type == "landUse" || type == "sellWay") {
					item[type].splice(index, 1);
				} else if (type == "city") {
					item.province = "";
					item.city = "";
					item.region = "";
				} else if (this.chooseFormItemOption[type]) {
					item[type] = "";
				} else if (this.chooseTimeOption[type] || this.chooseRangeOption[type]) {
					item[`${type}Start`] = "";
					item[`${type}End`] = "";
					item[type] = [];
				} else {
					item[type] = "";
					// this.nowFormItem.province = '';
					// this.nowFormItem.city = '';
					// this.nowFormItem.region = '';
				}
			},
			delSingleAll(type) {
				let item = this.formItem;
				item[type] = [];
			},

			changeTime(time) {
				if (!time) return "";
				let year = time.getFullYear();
				let month = time.getMonth() + 1;
				month = month < 10 ? `0${month}` : month;
				let day = time.getDate();
				return `${year}-${month}-${day}`;
			},
			changeRangeVal(val, type, index) {
				this.$set(this.formItem[type], index, val);
				// this.formItem[type][index] = val;
			},
			getSearchData() {
				let item = Object.assign({}, this.formItem);
				item.jdfbmGpdm = this.gpdm;
				if (item.dealState) {
					item.transactionStatus = [+item.dealState];
				}

				// 时间范围
				if (!item.announcementTimeStart || !item.announcementTimeEnd) {
					delete item.announcementTime;
				}
				if (!item.closingTimeStart || !item.closingTimeEnd) {
					delete item.closingTime;
				}
				if (!item.deadlineStart || !item.deadlineEnd) {
					delete item.deadline;
				}
				if (!item.startingTimeStart || !item.startingTimeEnd) {
					delete item.startingTime;
				}

				// 数值范围
				if (!item.plotRatioStart || !item.plotRatioEnd) {
					delete item.plotRatio;
				}
				if (!item.premiumRateStart || !item.premiumRateEnd) {
					delete item.premiumRate;
				}
				return item;
			},
			saveTemplate() {
				this.saveSearchData = JSON.stringify(this.getSearchData());
				this.showModalManage = true;
			},
			chooseTemplate(item) {
				let formItem = this.formItem;
				let params = JSON.parse(item.templateParams);
				// formItem = params;
				for (let key in params) {
					formItem[key] = params[key];
				}
				formItem.currentIndex = 1;
				this.getList();
			},
			getList() {
				// const obj = {
				//     size: this.pageSize,
				//     current: this.currentIndex,
				// };
				// let params = Object.assign(this.getSearchData(),obj);

				for (let key in this.chooseRangeOption) {
					// 传过值
					if (this.formItem[key].length) {
						let start = this.formItem[`${key}Start`];
						let end = this.formItem[`${key}End`];
						// 都是空值 继续
						if (!start && !end) continue;
						// 只有单一值 返回
						if (!start || !end) {
							// this.$Message.warning(`${this.chooseRangeOption[key]}，请选择一个范围`);
							this.$msg.error({
								text: `${this.chooseRangeOption[key]}，请选择一个范围`,
							});

							return;
						}
					}
				}
				this.loading = true;
				this.tableData = [];
				post_enterprise_tdcb_tdlb(this.getSearchData())
					.then((data) => {

						this.total = data.total;
						this.tableData = data.records;
						this.land = this.tableData[0];
					})
					.finally(() => {
						this.loading = false;
					});
			},
			pageSizeChange(size) {
				this.formItem.current = 1;
				this.formItem.size = size;
				this.getList();
			},
			pageChange(size) {
				this.formItem.current = size;
				this.getList();
			},

			tableClick(info) {
				let routeUrl = this.$router.resolve({
					path: `/Insight/PlaceDetail`,
					query: {
						id: info.id,
						type: "option",
					},
				});
				window.open(routeUrl.href, "_blank");
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "~@/style/common.scss";

	.page {
		width: 100%;
		min-height: calc(100vh - 80px);
		margin: 0 auto;
		padding-top: 20px;
		background: #fff;
		overflow: hidden;
		color: #000;

		.page-ct {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			min-width: 1200px;
			padding: 0 20px;
		}

		.option-box {
			width: 100%;
			margin-bottom: 10px;
			background-color: #fff;
			border: 1px solid #f1f1f1;

			.line {
				@include flex(flex-start);
				height: 48px;
				min-height: 48px;
				background-color: rgba(#f8f8f8, 0.47);

				.title {
					@include flex;
					width: 140px;
					height: 100%;
					flex-shrink: 0;
				}

				.des {
					position: relative;
					@include flex(flex-start);
					flex-grow: 1;
					height: 100%;
					min-height: 48px;
					padding-left: 40px;
					border-bottom: 1px solid rgba(#ccc, 0.48);
					flex-wrap: wrap;
					background-color: #fff;

					.split {
						margin: 0 8px;
						color: #cfcfcf;
					}

					.option-input {
						width: 200px;
						height: 30px;
						border: 1px solid rgba(228, 228, 228, 1);
						border-radius: 5px;

						&.sm {
							width: 86px;
						}
					}
				}

				.left,
				.right {
					@include flex(flex-start);
					flex: 1;
					height: 100%;
				}

				.right {
					border-left: 1px solid #f1f1f1;
					border-bottom: 1px solid #f1f1f1;
				}

				&.city-line {
					.ivu-select {
						margin-right: 10px;
					}
				}

				&.land-use {
					height: auto;
				}
			}

			.saixuan-line {
				padding: 0px 0 10px;
				height: 100px;

				.title {
					@include flex;
					width: 140px;
					height: 100%;
					background-color: rgba(#f8f8f8, 0.47);
				}
			}

			.more-btn {
				display: block;
				justify-content: center;
				margin-left: auto;
				margin-right: 10px;
				text-align: center;
			}

			.more {
				position: relative;
				border: 2px solid transparent;

				.title {
					color: #666;
					background-color: #fffdef;
				}

				.des {
					border-left: 1px solid #f1f1f1;
				}

				&.open {
					border-color: #edd189;

					.switch {
						border-color: #edd189;

						/deep/ .ivu-icon {
							transform: rotate(0);
						}
					}
				}
			}

			.btn-line {
				@include flex;
				margin: 15px auto;

				.btn {
					@include flex;
					width: 60px;
					height: 30px;
					margin-right: 15px;
					background-color: #f7f7f7;
					border: 1px solid #ececec;
					cursor: pointer;

					&.disable {
						color: #eee;
						background-color: #fff;
						cursor: auto;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.switch {
				@include flex;
				position: absolute;
				width: 104px;
				height: 20px;
				left: 50%;
				bottom: -20px;
				color: #b7b7b7;
				background-color: #fff;
				border: 2px solid transparent;
				border-top: none;
				transition: transform 0.3s;
				transform: translate(-50%, 0);
				cursor: pointer;

				/deep/ .ivu-icon {
					transform: rotate(180deg);
					transition: transform 0.3s;
				}
			}

			input {
				padding-left: 10px;
			}
		}

		.action-line {
			@include flex(flex-end);
			width: 100%;
			margin: 10px 0;

			.btn {
				@include flex;
				width: 72px;
				height: 28px;
				margin-right: 8px;
				color: #fff;
				background: rgba(53, 183, 224, 1);
				border-radius: 5px;
				cursor: pointer;

				&.save {
					background-color: #30ce77;
				}

				&.my {
					background-color: #6595fc;
				}
			}
		}

		.choose-box {
			@include flex(flex-start);
			width: 100%;
			min-height: 40px;
			padding: 0 5px;
			background-color: #f0f0f0;

			.title {
				flex-shrink: 0;
				color: #666;
			}

			.des {
				// @include flex(flex-start,flex-start);
			}
		}

		.table-box {
			width: 100%;

			.ivu-page {
				position: relative;
				margin-top: 15px;
				text-align: right;
			}
		}
	}

	.select-card {
		display: inline-block;
		margin: 0 4px 0 0;
		position: relative;

		button {
			height: 24px;
		}
	}

	.select-card__content {
		padding: 8px 16px;
		background: #fff;
		visibility: visible;
		font-size: 14px;
		position: absolute;
		z-index: 1060;
		width: 260px;
		height: 56px;
		overflow: scroll;
		position: absolute;
		will-change: top, left;
		top: 24px;
		left: -43px;
		background-clip: padding-box;
		border-radius: 4px;
		box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
		display: none;
	}

	.select-card:hover {
		.select-card__content {
			display: block;
		}
	}
</style>
