<template>
	<div class="home">
		<dataplat-search @submit="handleSubmit" ref="dataplatSearch"></dataplat-search>
		<DownloadBtn :id="1" :type=1 @exportData="exportData" :style="{
        position: 'relative',
        left: 0,
        'margin-bottom': '10px',
        'background-color': 'rgba(241, 110, 113, 1)',
      }"></DownloadBtn>
		<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
			@changePageSize="handleChangePageSize"></dataplat-content>
	</div>
</template>

<script>
	import DownloadBtn from "@/components/DownloadBtn";
	import SearchPanel from "./components/total/SearchPanel";
	import ContentPanel from "@/components/ContentPanel";
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	import {
		post_enterprise_tdcb_ztdcb,
		post_enterprise_tdcb_xztdcb,
	} from "@/api/houseEnterprise";
	export default {
		name: "total",
		components: {
			"dataplat-search": SearchPanel,
			"dataplat-content": ContentPanel,
			DownloadBtn,
		},
		computed: {
			code() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			},
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				total: 0,
				searchForm: {},
				downForm: {}
			};
		},
		watch: {
			code(newVal, oldVal) {
				this.$refs.dataplatSearch.handleSearch();
			},
		},

		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {


		},
		methods: {
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			exportData() {
				const type = this.downForm.lx;
				let requestType = {
					ztdcb: post_enterprise_tdcb_ztdcb,
					xztdcb: post_enterprise_tdcb_xztdcb,
				};
				let requestParam = Object.assign(this.downForm, {
					gpdm: this.code, // 股票代码
				});
				requestParam = JSON.parse(JSON.stringify(requestParam));
				delete requestParam.lx;
				requestParam.size = 1000000
				requestParam.current = 1
				const columns = [{
						title: "日期",
						key: "rq",
						ellipsis: true,
						tooltip: true,
						align: "center",
						width: 120,
					},
					{
						title: "本公司(集团)",
						align: "center",
						ellipsis: true,
						tooltip: true,
						children: [{
								title: "占地面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "占地面积"), h("div", "(万m²)")]);
								},
								key: "zdmj",
								align: "center",
								ellipsis: true,
								tooltip: true,
								width: 140,
							},
							{
								title: "建筑面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "建筑面积"), h("div", "(万m²)")]);
								},
								key: "jzmj",
								ellipsis: true,
								tooltip: true,
								width: 140,
								align: "center",
							},
							{
								title: "规划建筑面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "规划建筑面积"), h("div", "(万m²)")]);
								},
								key: "ghjzmj",
								ellipsis: true,
								tooltip: true,
								align: "center",
								width: 180,
							},
							{
								title: "总地价",
								renderHeader: (h, index) => {
									return h("div", [h("div", "总地价"), h("div", "(亿元)")]);
								},
								key: "zdj",
								ellipsis: true,
								tooltip: true,
								align: "center",
								width: 140,
							},
							{
								title: "平均土地成本",
								renderHeader: (h, index) => {
									return h("div", [
										h("div", "平均土地成本"),
										h("div", "(元/m²)"),
									]);
								},
								key: "tdcb",
								ellipsis: true,

								tooltip: true,

								align: "center",
								width: 140,
							},
						],
					},
					{
						title: "权益",
						ellipsis: true,
						tooltip: true,
						align: "center",
						children: [{
								title: "占地面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "占地面积"), h("div", "(万m²)")]);
								},
								key: "zdmjqy",
								ellipsis: true,
								tooltip: true,
								align: "center",
								width: 140,
							},
							{
								title: "建筑面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "建筑面积"), h("div", "(万m²)")]);
								},
								key: "jzmjqy",
								ellipsis: true,

								tooltip: true,

								width: 140,
								align: "center",
							},
							{
								title: "规划建筑面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "规划建筑面积"), h("div", "(万m²)")]);
								},
								key: "ghjzmjqy",
								ellipsis: true,

								tooltip: true,

								align: "center",
								width: 180,
							},
							{
								title: "总地价",
								renderHeader: (h, index) => {
									return h("div", [h("div", "总地价"), h("div", "(亿元)")]);
								},
								key: "zdjqy",
								ellipsis: true,

								tooltip: true,

								align: "center",
								width: 140,
							},
							{
								title: "平均土地成本",
								renderHeader: (h, index) => {
									return h("div", [
										h("div", "平均土地成本"),
										h("div", "(元/m²)"),
									]);
								},
								key: "tdcbqy",
								ellipsis: true,
								tooltip: true,
								align: "center",
								width: 140,
							},
						],
					},
				];
				requestType[type](requestParam)
					.then((data) => {
						// this.tableColumns = columns;
						// this.total = data.total;
						// this.tableList = data.records || [];
						let tHeader = [];
						let filterVal = []

						function _setData(arr, title) {
							if (Array.isArray(arr)) {
								arr.forEach(i => {
									if (i.hasOwnProperty('children') && i.children.length != 0) {
										if (i.hasOwnProperty('key')) {
											tHeader.push(i.title)
											filterVal.push(i.key)
										}
										_setData(i.children, i.title)
									} else {
										if (i.hasOwnProperty('key')) {
											tHeader.push(`${i.title}${title?`(${title})`:''}`)
											filterVal.push(i.key)
										}
									}
								})
							}

						}
						_setData(columns)
						const resData = formatJson(filterVal, data.records);
						downloadData(tHeader, resData, "土地储备");

					})
					.catch((e) => {

					});
			},
			handleChangePageSize(size) {
				this.searchForm.size = size;
				this.handleSubmit(this.searchForm);
			},
			handleSubmit(params) {
				const type = params.lx;
				let requestType = {
					ztdcb: post_enterprise_tdcb_ztdcb,
					xztdcb: post_enterprise_tdcb_xztdcb,
				};
				let requestParam = Object.assign(params, {
					gpdm: this.code, // 股票代码
				});
				requestParam = JSON.parse(JSON.stringify(requestParam));
				this.downForm = JSON.parse(JSON.stringify(requestParam))
				delete requestParam.lx;
				const columns = [{
						title: "日期",
						key: "rq",
						ellipsis: true,
						tooltip: true,

						align: "center",
						width: 120,
					},
					{
						title: "本公司(集团)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						children: [{
								title: "占地面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "占地面积"), h("div", "(万m²)")]);
								},
								key: "zdmj",
								align: "center",
								ellipsis: true,
								tooltip: true,
								width: 140,
							},
							{
								title: "建筑面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "建筑面积"), h("div", "(万m²)")]);
								},
								key: "jzmj",
								ellipsis: true,
								tooltip: true,
								width: 140,
								align: "center",
							},
							{
								title: "规划建筑面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "规划建筑面积"), h("div", "(万m²)")]);
								},
								key: "ghjzmj",
								ellipsis: true,
								tooltip: true,
								align: "center",
								width: 180,
							},
							{
								title: "总地价",
								renderHeader: (h, index) => {
									return h("div", [h("div", "总地价"), h("div", "(亿元)")]);
								},
								key: "zdj",
								ellipsis: true,
								tooltip: true,
								align: "center",
								width: 140,
							},
							{
								title: "平均土地成本",
								renderHeader: (h, index) => {
									return h("div", [
										h("div", "平均土地成本"),
										h("div", "(元/m²)"),
									]);
								},
								key: "tdcb",
								ellipsis: true,

								tooltip: true,

								align: "center",
								width: 140,
							},
						],
					},
					{
						title: "权益",
						ellipsis: true,
						tooltip: true,

						align: "center",
						children: [{
								title: "占地面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "占地面积"), h("div", "(万m²)")]);
								},
								key: "zdmjqy",
								ellipsis: true,
								tooltip: true,
								align: "center",
								width: 140,
							},
							{
								title: "建筑面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "建筑面积"), h("div", "(万m²)")]);
								},
								key: "jzmjqy",
								ellipsis: true,

								tooltip: true,

								width: 140,
								align: "center",
							},
							{
								title: "规划建筑面积",
								renderHeader: (h, index) => {
									return h("div", [h("div", "规划建筑面积"), h("div", "(万m²)")]);
								},
								key: "ghjzmjqy",
								ellipsis: true,

								tooltip: true,

								align: "center",
								width: 180,
							},
							{
								title: "总地价",
								renderHeader: (h, index) => {
									return h("div", [h("div", "总地价"), h("div", "(亿元)")]);
								},
								key: "zdjqy",
								ellipsis: true,

								tooltip: true,

								align: "center",
								width: 140,
							},
							{
								title: "平均土地成本",
								renderHeader: (h, index) => {
									return h("div", [
										h("div", "平均土地成本"),
										h("div", "(元/m²)"),
									]);
								},
								key: "tdcbqy",
								ellipsis: true,
								tooltip: true,
								align: "center",
								width: 140,
							},
						],
					},
				];
				requestType[type](requestParam)
					.then((data) => {
						this.tableColumns = columns;
						this.total = data.total;
						this.tableList = data.records || [];
					})
					.catch((e) => {

					});
			},
		},
	};
</script>
