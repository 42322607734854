<template>
	<div class="right-box">
	<dataplat-total></dataplat-total>
		<!-- <Tabs value="enterpriseAnnouncement" class="tab-box">
			<TabPane label="土地储备" name="enterpriseAnnouncement">
				<dataplat-total></dataplat-total>
			</TabPane>
			<TabPane label="土地招拍挂" disabled name="statistics">
				<div class="list-header">
					<div @click="() => handleChangeHeader(false)" :class="visiableLand ? '' : 'acitve'">土地统计</div>
					<div @click="() => handleChangeHeader(true)" :class="visiableLand ? 'acitve' : ''">土地列表</div>
				</div>
				<dataplat-landStatistics v-if="!visiableLand"></dataplat-landStatistics>
				<dataplat-land-list v-else></dataplat-land-list>
			</TabPane>
		</Tabs> -->
	</div>
</template>

<script>
	// @ is an alias to /src
	import Total from "./components/Total"
	import LandStatistics from "./components/LandStatistics"
	import LandList from "./components/LandList"
	import dataReports from '@/mixins/dataReports'
	export default {
		name: 'landReserve',
		mixins: [dataReports],
		components: {
			'dataplat-total': Total,
			// 'dataplat-landStatistics': LandStatistics,
			// 'dataplat-land-list': LandList,
		},
		data() {
			return {
				visiableLand: false,
			}
		},
		methods: {
			handleChangeHeader(value) {
				this.visiableLand = value;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.list-header {
		display: flex;

		div {
			padding: 8px 40px;
			background: #e7f0fc;
			margin-right: 10px;
		}

		div:hover,
		.acitve {
			background: #00b6ff;
			color: #fff;
		}
	}
</style>
