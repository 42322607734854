<template>
	<div class="home">
		<dataplat-search @submit="handleSubmit"></dataplat-search>
		<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
			@changePageSize="handleChangePageSize"></dataplat-content>
		<dataplat-chart :list="chartList"></dataplat-chart>
	</div>
</template>

<script>
	import SearchPanel from "./components/landStatistics/SearchPanel"
	import ChartPanel from "./components/landStatistics/ChartPanel"
	import ContentPanel from "@/components/ContentPanel"
	import {
		post_enterprise_tdcb_tdtjsj,
		post_enterprise_rz_rzdq
	} from '@/api/houseEnterprise'
	export default {
		name: 'total',
		components: {
			'dataplat-search': SearchPanel,
			'dataplat-content': ContentPanel,
			'dataplat-chart': ChartPanel,
		},
		computed: {
			gpdm() {
				return this.$store.state.componeyCode
			}
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				chartList: [],
				total: 0,
				searchForm: {}
			}
		},
		methods: {
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			handleSubmit(params) {
				const type = params.lx;
				let requestParam = Object.assign(params, {
					gpdm: this.gpdm // 股票代码
				});
				requestParam = JSON.parse(JSON.stringify(requestParam))
				delete requestParam.lx;
				const columns = [{
					title: '时间',
					align: 'center',
					ellipsis: true,
					tooltip: true,

					key: 'landTime',
				}, {
					title: '宗数',
					align: 'center',
					ellipsis: true,
					tooltip: true,

					key: 'religion',
				}, {
					title: '用地面积',
					align: 'center',
					ellipsis: true,
					tooltip: true,

					renderHeader: (h, index) => {
						return h("div", [
							h("div", '用地面积'),
							h("div", '(万m²)')
						]);
					},
					key: 'landArea',
				}, {
					title: '规划建筑面积',
					ellipsis: true,
					tooltip: true,

					renderHeader: (h, index) => {
						return h("div", [
							h("div", '规划建筑面积'),
							h("div", '(万m²)')
						]);
					},
					align: 'center',
					key: 'planArea',
				}, {
					title: '起始价',
					ellipsis: true,
					tooltip: true,

					renderHeader: (h, index) => {
						return h("div", [
							h("div", '起始价'),
							h("div", '(万元)')
						]);
					},
					align: 'center',
					key: 'startPrice',
				}, {
					title: '成交价',
					ellipsis: true,
					tooltip: true,

					renderHeader: (h, index) => {
						return h("div", [
							h("div", '成交价'),
							h("div", '(万元)')
						]);
					},
					align: 'center',
					key: 'transactionPrice',
				}, {
					title: '楼面价',
					ellipsis: true,
					tooltip: true,

					renderHeader: (h, index) => {
						return h("div", [
							h("div", '楼面价'),
							h("div", '(元/m²)')
						]);
					},
					align: 'center',
					key: 'floorPrice',
				}, {
					title: '溢价率',
					align: 'center',
					ellipsis: true,
					tooltip: true,

					key: 'premiumRate',
				}];
				post_enterprise_tdcb_tdtjsj(requestParam).then(data => {
					this.tableColumns = columns;
					this.total = data.total;
					this.tableList = data.records || [];
				}).catch(e => {

				});

				post_enterprise_rz_rzdq(requestParam).then(data => {
					this.chartList = data.records;
				}).catch(e => {

				});
			}
		}
	}
</script>
