<template>
  <div class="home">
    <Card>
      <Form :model="searchForm" :label-width="120">
        <FormItem label="周期">
          <Select
            v-model="searchForm.timePeriod"
            style="width: 80px"
            @on-change="
              (value) => {
                this.handleChangeCycle('周期', 'string', 'timePeriod', value);
              }
            "
          >
            <Option v-for="item in zqList" :value="item.id" :key="item.id">{{
              item.name
            }}</Option> </Select
          >&nbsp;
          <DateRange
            v-model="searchForm.landTime"
            :dateType="dateType"
            ref="DateRange"
            @change="
              (value) =>
                this.handleChangeOption('日期', 'date', 'landTime', value)
            "
          ></DateRange>
        </FormItem>
        <!-- <FormItem label="出让方式">
                    <CheckboxGroupAll
                        v-model="searchForm.sellTypes"
                        @change="(data) => this.handleChangeValue('出让方式', 'list', 'sellTypes', data)"
                        :list="sellTypes"
                        :checkboxList="sellTypesAll"
                        :tree="false"
                    >
                    </CheckboxGroupAll>
                </FormItem>-->
        <FormItem label="土地用途">
          <CheckboxGroupAll
            v-model="searchForm.landUses"
            @change="
              (data) =>
                this.handleChangeValue('土地用途', 'list', 'landUses', data)
            "
            :list="landUses"
            :checkboxList="landUsesAll"
            :tree="false"
          ></CheckboxGroupAll>
        </FormItem>
        <FormItem>
          <Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
        </FormItem>
      </Form>
    </Card>
    <div class="tagbox">
      <template v-if="Object.keys(selectedList).length !== 0">
        已选条件：
        <template v-for="item in selectedList">
          <span v-if="item !== null" :key="item.formItem">
            <Tag
              v-if="item"
              type="border"
              closable
              @on-close="() => handleCloseAll(item)"
            >
              <span>{{ item.title }}:</span>
              <span v-for="(name, index) in item.data" :key="index"
                >{{ index > 0 ? "," : "" }}{{ name }}</span
              >
            </Tag>
            <span v-else>
              <Tag
                v-for="(name, index) in item.data"
                :key="name"
                type="border"
                closable
                @on-close="() => handleClose(index, item)"
              >
                <span v-if="item">{{ item.title }}:</span>
                <span>{{ name }}</span>
              </Tag>
            </span>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import tagBoxMixin from "@/views/share/tagBoxMixin";
import dateHandleMixin from "@/views/share/dateHandleMixin";
import CheckboxGroupAll from "@/components/CheckboxGroupAll";
import {
  get_land_dim_landUse,
  get_land_dim_sellWay,
} from "@/api/houseEnterprise";
import { checkDate } from "@/utils/index";
export default {
  name: "SearchPanel",
  components: {
    CheckboxGroupAll,
  },
  mixins: [tagBoxMixin, dateHandleMixin],
  mounted() {
    this.init();
  },
  data() {
    return {
      searchForm: {
        landTime: ["2019-01", "2020-01"], // 时间
        landUses: [], // 土地用途(多选)
        provinceIds: [], // 区域id
        sellTypes: [], //出让方式(多选)
        timePeriod: "2", // 时间周期(日,月度,季度,年度)
        // tradingStatus: '1', // 交易状态(具体枚举值根据维度枚举动态获取)
        size: 10,
        current: 1,
      },
      zqList: [
        // {
        //     id: '1',
        //     name: '日',
        // },
        {
          id: "2",
          name: "月度",
        },
        {
          id: "3",
          name: "季度",
        },
        {
          id: "4",
          name: "年度",
        },
      ],
      sellTypes: [],
      landUses: [],
      sellTypesAll: [],
      landUsesAll: [],
      dateType: "month",
      selectedList: {}, //已选条件
    };
  },
  methods: {
    init() {
      this.handleChangeOption("日期", "date", "landTime", [
        "2019-01",
        "2020-01",
      ]);
      this.getCheckboxList("landUses");
      this.getCheckboxList("sellTypes");
    },
    getCheckboxList(type) {
      let requestType = {
        landUses: get_land_dim_landUse,
        sellTypes: get_land_dim_sellWay,
      };
      requestType[type]()
        .then((data) => {
          if (type === "landUses") {
            data = data.filter((item) => !item.name.includes("+"));
          }
          this.handleCheckbox(data, type);
        })
        .catch((e) => {

        });
    },
    handleCheckbox(data, name) {
      let list = [];
      data.forEach((item) => {
        list.push(item.id);
      });
      this[name] = data.map((item) => {
        item.title = item.name;
        return item;
      });
      this[`${name}All`] = list;
    },
    handleChangeValue(name, type, formItem, value) {
      const checkedList = value;
      const checkboxList = this[formItem];
      let list = [];
      checkedList.map((item) => {
        for (let i = 0, len = checkboxList.length; i < len; i++) {
          if (item === checkboxList[i].id) {
            list.push(checkboxList[i].name);
            break;
          }
        }
      });
      this.handleChangeOption(name, type, formItem, list);
    },
    handleCloseAll(item) {
      const { choosedType, data, formItem: type } = item;

      let selectedList = Object.assign({}, this.selectedList);
      selectedList[type] = null;
      this.selectedList = selectedList;
      this.searchForm[type] = [];
    },
    handleChangeCycle(name, type, formItem, value) {
      this.searchForm[formItem] = value;
      if (value === "4") {
        this.dateType = "year";
      } else if (value === "1") {
        this.dateType = "date";
      } else if (value === "3") {
        this.dateType = "jd";
      } else {
        this.dateType = "month";
      }
      this.searchForm.landTime = ["", ""];
      this.$refs.DateRange.clearAll();
      delete this.selectedList.landTime;
    },
    handleSearch() {
      const { landTime } = this.searchForm;

      if (!checkDate(landTime[0], landTime[1])) {
        this.$msg.error({ type: "remind", code: 4015 });

        return;
      }
      let searchForm = this.handleDateRule(
        landTime,
        "landTime",
        "日期",
        this.searchForm
      );
      if (searchForm === null) {
        return;
      }
      this.$emit("submit", searchForm);
    },
  },
};
</script>
